@import "en-web-components/dist/en/en.css";

body {
  margin: 0px;
  font-family: var(--en-font-family-primary);
}

.ag-theme-alpine-dark {
  --ag-font-family: var(--en-font-family-primary);
}

.ag-theme-alpine-dark .ag-cell {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.ag-theme-alpine-dark .ag-row,
.ag-theme-alpine-dark .ag-cell {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

p {
  margin: 0px;
  font-size: 16px;
}

.pointer-cursor {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

strong,
b {
  font-weight: 600 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.MuiDialog-root {
  z-index: 888888 !important;
}

.fontNormal {
  p {
    &.MuiTypography-body1 {
      font-weight: normal;
    }
  }
}

.fontItalic {
  p {
    &.MuiTypography-body1 {
      font-weight: normal;
      font-style: italic;
    }
  }
}
.MuiDialog-container {
  .MuiPickersModal-dialogRootWider {
    min-width: 335px;
  }
}

.MuiPickersBasePicker-container {
  .MuiGrid-grid-xs-6 {
    justify-content: flex-end;
  }
  .MuiButton-label {
    .MuiTypography-subtitle1 {
      font-size: 20px;
    }

    .MuiTypography-h4 {
      font-size: 30px;
    }
    .MuiTypography-h3 {
      font-size: 30px;
      margin-right: 5px;
    }
  }
  .MuiPaper-rounded {
    border-radius: 0px;
    background-color: #3d3f52;
  }
  .MuiPickersToolbar-toolbar {
    background-color: #3d3f52;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
    .MuiGrid-root {
      z-index: 88888;
      .MuiGrid-root {
        z-index: 88888;
        .MuiPickerDTToolbar-separator {
          margin-bottom: 6px;
        }
      }
    }
  }
  .MuiPickerDTTabs-tabs {
    background-color: #007e91;
    padding-top: 20px;
    margin-top: 30px;
  }
  .MuiPickersDay-daySelected {
    background-color: #3d3f52;
  }
  .MuiPickersDay-daySelected:hover {
    background-color: #3d3f52;
  }
  .MuiTypography-colorPrimary {
    color: #3d3f52;
  }
  .MuiPickersClock-pin {
    background-color: #3d3f52;
  }
  .MuiPickersClockPointer-pointer {
    background-color: #3d3f52;
  }
  .MuiPickersClockPointer-noPoint {
    background-color: #3d3f52;
  }
  .MuiPickersClockPointer-thumb {
    border: 14px solid #3d3f52;
  }
  .MuiTabs-indicator {
    background-color: #3d3f52;
  }
}
.MuiPickersModal-withAdditionalAction {
  .MuiButton-textPrimary {
    color: #3d3f52;
  }
}

html,
body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

.barSeries {
  > rect {
    rx: 3px;
  }
}

.w-100 {
  width: 100%;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
}

.ag-horizontal-left-spacer {
  visibility: hidden;
}
.ag-horizontal-right-spacer {
  visibility: hidden;
}

.ag-body-horizontal-scroll-viewport {
  scrollbar-width: thin;
}

.ag-body-vertical-scroll-viewport {
  scrollbar-width: thin;
}
.react-grid-item.react-grid-placeholder {
  background: #000;
  opacity: 0.1;
  transition-duration: 100ms;
  z-index: 2;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

// For Autofill suggesstion text fix
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@keyframes autofill {
  0%,
  100% {
    color: #8e8c92;
    background: transparent;
  }
}

@-webkit-keyframes autofill {
  0%,
  100% {
    color: #8e8c92;
    background: transparent;
  }
}

input:-webkit-autofill {
  animation-delay: 1s;
  -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
  animation-name: autofill;
  -webkit-animation-name: autofill;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}
.ag-cell {
  overflow: visible !important;
}
.ag-cell-sm-padding {
  padding-left: 4px;
  padding-right: 4px;
}
.custom-ag-cell {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  user-select: text;
}
.custom-ag-cell-disable-checkbox {
  .ag-selection-checkbox {
    opacity: 0.4;
    pointer-events: none;
  }
}
.ag-pinned-left-header {
  border-right: none !important;
}
.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
  border-right: none !important;
}
.custom-ag-header {
  display: none;
}
.ag-header-overflow {
  overflow: visible;
}
.remove-ag-header-border {
  .ag-header-cell-resize:after {
    content: none;
  }
}
.align-pinned-ag-header-left {
  left: 0 !important;
}
.align-ag-header-networkServiceGroup {
  left: 0 !important;
  min-width: 320px;
}
.align-ag-cell-networkServiceGroup {
  left: 70px !important;
}
.custom-ag-cell-align-checkbox {
  .ag-cell-wrapper {
    height: 100%;
    .ag-drag-handle {
      position: relative;
      left: 0px;
      align-items: center;
      margin-right: -30px;
    }
    .ag-selection-checkbox {
      margin-right: 0;
      position: relative;
      left: 44px;
    }
  }
}
// .ag-cell-first-right-pinned,
// .ag-pinned-right-header  {
//   border-right: none !important;
//   border-left: none !important;
// }
// .ag-header-cell-resize{
//   display: none
// }

.ag-header-cell-text {
  font-size: 16px;
  font-weight: 600;
}

/* Error Boundary Styles */
.error-boundary-root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  margin-top: 20px;
}

.text-align {
  text-align: center;
}
/* Error Boundary Styles */

.ag-overlay-no-rows-center {
  display: none !important;
}

.ag-select-text-cell {
  user-select: none !important; /* Disable text selection for grid cells */
}

.ag-text-cell {
  font-size: 16px;
  user-select: text; /* Enable text selection for grid cells */
}

.ag-row-hover {
  z-index: 1 !important; /* Ensure hover effect is visible over other rows */
}

.ag-row-level-1 {
  user-select: text; /* Enable text selection for expanded views */
}

.ag-row-loading {
  border-bottom: none;
}

.ag-side-buttons {
  padding-top: 0; /* removes the padding from the top of the right side toolbar strip */
}

.ag-pinned-right-sticky-top {
  overflow: visible; /* Allows the 3-dot menu to overflow out of the sticky container that ag-grid attaches to rows that stick to top when expanded */
}

.ag-cell-no-padding {
  padding: 0 !important;
}

.ag-cell-flex {
  display: flex;
}